import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/picnew01.jpg'
import pic02 from '../images/picnew02.jpg'
import pic03 from '../images/picnew03.jpg'
import banner from '../images/banner.png'
import first from '../images/1.svg'
import second from '../images/2.svg'
import third from '../images/3.svg'
import fourth from '../images/4.svg'
import fifth from '../images/5.svg'
import sixth from '../images/6.svg'
import shangai from '../images/shangai.jpeg';
import berlin from '../images/berlin.jpg';
import GoogleMapReact from 'google-map-react'
import './map.css'
import { Icon } from '@iconify/react'
import locationIcon from '@iconify/icons-mdi/map-marker'

const location = {
  address: 'Shanghai Lot A, Floor 11, No.818 Dongfang Road, Shanghai',
  lat: 31.164280,
  lng: 121.329122,
}
const location2 = { 
  address: 'Berlin Bucholzer Str. 4 10437, Berlin',
  lat: 52.545160,
  lng: 13.415400,
}
const LocationPin = ({ text }) => (
  <div className="pin">
    <Icon icon={locationIcon} className="pin-icon" />
    <p className="pin-text">{text}</p>
  </div>
)

class Main extends React.Component {
  
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Growth Marketing Solutions</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              borderTop: '1px solid white',
            }}
          >
            <div className="logo" style={{ marginTop: 30 }}>
              <span
                className="icon fa-lightbulb-o"
                style={{ fontSize: 64 }}
              ></span>
            </div>
          </div>
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              padding: 0,
              marginBottom: 5,
            }}
          >
            Drive Incremental Growth & Intent-based Conversions
          </p>
          <p
            style={{
              textAlign: 'center',
              borderBottom: '1px solid white',
              paddingBottom: 15,
            }}
          >
            We seek and provide the best possible solutions for your business
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div className="logo">
              <span className="icon fa-trophy" style={{ fontSize: 64 }}></span>
            </div>
          </div>
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              padding: 0,
              marginBottom: 5,
            }}
          >
            Growth
          </p>
          <p
            style={{
              textAlign: 'center',
              borderBottom: '1px solid white',
              paddingBottom: 15,
            }}
          >
            Work with our award-winning team to grow your business or solution
            to its full potential
          </p>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div className="logo">
              <span className="icon fa-comment" style={{ fontSize: 64 }}></span>
            </div>
          </div>
          <p
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              padding: 0,
              marginBottom: 5,
            }}
          >
            Support
          </p>
          <p
            style={{
              textAlign: 'center',
              borderBottom: '1px solid white',
              paddingBottom: 15,
            }}
          >
            We respect our partners opinions and provide continuous support any
            time
          </p>
          {close}
        </article>
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Are you a publisher?</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Get the party started! Contact us in order to analyze the potential
            opportunities that are available. Let's make this a thrilling ride!
          </p>
          {close}
        </article>
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Are you an advertiser?</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Reach your audience in new enivornments. We are committed to finding
            the best way to help you optimize your mobile strategy by building
            solutions to meet your desired goals.
          </p>
          {close}
        </article>
        <article
          id="oem"
          className={`${this.props.article === 'oem' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">
            Get Your App Discovered <br /> With Top OEMs
          </h2>
          <span
            className="image main"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <img
              src={banner}
              style={{ maxWidth: 200, width: '100%', textAlign: 'center' }}
              alt=""
            />
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              borderTop: '1px solid white',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={first} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Beat Competition
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                Benefit from first mover advantage
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={second} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Scale
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                Access to 1 billion users worldwide
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={third} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Zero Fraud
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                Access real users with high intent to download
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={fourth} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Constant Support
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                We are there for you
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={fifth} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Reporting Insight
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                Ad slots, impressions, spend and downloads
              </p>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingTop: 30,
              borderBottom: '1px solid white',
            }}
          >
            <div style={{ width: '35%', flexDirection: 'col' }}>
              <div className="logo">
                <img src={sixth} />
              </div>
            </div>
            <div
              style={{
                width: '65%',
                flexDirection: 'col',
                alignSelf: 'center',
              }}
            >
              <p style={{ fontWeight: 'bold', marginTop: 5, marginBottom: 2 }}>
                Free Publishing
              </p>
              <p style={{ textAlign: 'left', paddingBottom: 15 }}>
                Get your app published for free on the AppGallery
              </p>
            </div>
          </div>

          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="Contact Form" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="Contact Form" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>
        
        <article
          id="offices"
          className={`${this.props.article === 'offices' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <div className="map">
            <h2 className="map-h2">Come Visit Us At Our Offices</h2>
            <p style={{marginTop: 25}}>Bucholzer str. 4 10437, Berlin</p>
          <img style={{width: '100%'}}  src={berlin}/>
            <p>
            Lot A, Floor 11, No.818 Dongfang Road, Shanghai
          </p>
          <img style={{width: '100%'}} src={shangai} />

          

            
          </div>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
